class ZIDXAccountLeadView implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountLeadViewContainer";
    }
    getMatchURL(){
        return "/account/leads/view";
    }
    render(){
        // console.log("lead view");
    }
}